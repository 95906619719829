import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as manageUsers from "../app/modules/UserSettings/redux/manageUsersRedux";
import * as manageRoles from "../app/modules/UserSettings/redux/manageRolesRedux";
import * as manageClients from "../app/modules/ClientSettings/redux/manageClientsRedux";
import * as manageDesigners from "../app/modules/DesigerSettings/redux/manageDesignersRedux";
import * as manageTeams from "../app/modules/TeamSettings/redux/manageTeamRedux";
import * as intakeForm from "../app/modules/ConfigSettings/redux/intakeFormRedux";
import * as emailNotification from "../app/modules/ConfigSettings/redux/emailNotificationRedux";
import * as feedbackForm from "../app/modules/ConfigSettings/redux/feedbackFormRedux";
import * as manageReports from "../app/modules/Reports/redux/manageReportsRedux";
import * as general from "./generalReducer";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";


const appReducer = combineReducers({
  general: general.reducer,
  auth: auth.reducer,
  manageUsers: manageUsers.reducer,
  manageRoles: manageRoles.reducer,
  manageClients: manageClients.reducer,
  manageDesigners: manageDesigners.reducer,
  manageTeams: manageTeams.reducer,
  intakeForm: intakeForm.reducer,
  emailNotification: emailNotification.reducer,
  feedbackForm: feedbackForm.reducer,
  manageReports: manageReports.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
});

export const rootReducer = (state, action) => {
  if (action.type === auth.actionTypes.setShouldLogout) {
    let { flag } = action.payload;
    if (flag === true) {
      state = undefined;
    }
  }

  return appReducer(state, action);
};

export function* rootSaga() {
  yield all([
    auth.saga(),
    manageUsers.saga(),
    manageRoles.saga(),
    manageClients.saga(),
    manageDesigners.saga(),
    manageTeams.saga(),
    intakeForm.saga(),
    emailNotification.saga(),
    feedbackForm.saga(),
    manageReports.saga(),
  ]);
}
