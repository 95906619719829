import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import UserSettingsPage from "./modules/UserSettings/UserSettingsPage";
import ClientSettingsPage from "./modules/ClientSettings/ClientSettingsPage";
import DesignerSettingsPage from "./modules/DesigerSettings/DesignerSettingsPage";
// import TeamSettingsPage from "./modules/TeamSettings/TeamSettingsPage";
import ConfigSettingsPage from "./modules/ConfigSettings/ConfigSettingsPage";
import ReportsSearchPage from "./modules/Reports/ReportsPage";
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/user-settings" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/user-settings" component={UserSettingsPage} />
        <Route path="/client-settings" component={ClientSettingsPage} />
        <Route path="/designer-settings" component={DesignerSettingsPage} />
        {/* <Route path="/team-settings" component={TeamSettingsPage} /> */}
        <Route path="/config-settings" component={ConfigSettingsPage} />
        <Route path="/reports" component={ReportsSearchPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
